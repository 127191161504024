import React, { useEffect, useMemo } from 'react';
import { createFileRoute, Link } from '@tanstack/react-router';
import {
  Spinner,
  Button,
  Table,
  TableHeader,
  TableBody,
  TableColumn,
  TableRow,
  TableCell,
  Checkbox,
} from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import { getTopLevelDomain } from '../utils/get-top-level-domain';
import rrwebPlayer from 'rrweb-player';
import { Resizable } from 're-resizable';
import JsonView from '@uiw/react-json-view';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/theme-textmate';
import logo from '../assets/logo-dark-colored.svg';
import 'rrweb-player/dist/style.css';
import { EventRecord } from '../utils/event-record';
import useLocalStorage from '../utils/use-local-storage';
import { IconArrowLeft, IconExternalLink } from '@tabler/icons-react';
import { NetworkTable } from '../components/NetworkTable';
import { DebuggerEvent } from '../utils/network-event-record';
import { formatState } from './index';

type PlayerSearch = {
  crawlrunGuid?: string;
  taskGuid?: string;
  key: string;
  apiKey?: string;
};

export const Route = createFileRoute('/player')({
  component: PlayerPage,
  validateSearch: (search: Record<string, unknown>): PlayerSearch => {
    // validate and parse the search params into a typed state
    return {
      crawlrunGuid: (search.crawlrunGuid as string) || '',
      taskGuid: (search.taskGuid as string) || '',
      key: search.key as string,
      apiKey: search._apikey as string,
    };
  },
  onLeave: () => {
    try {
      // @ts-expect-error Destroy is not in types
      window.player?.$destroy();
      window.player = undefined;
    } catch (err) {
      // Do nothing
    }
  },
});

function PlayerPage() {
  const routeSearch = Route.useSearch();

  const [tab, setTab] = React.useState('summary');
  const [playedEvents, setPlayedEvents] = React.useState<EventRecord[]>([]);
  const [debuggerBlob, setDebuggerBlob] = React.useState<string | null>(null);
  const [isScrollable, setScrollable] = React.useState<boolean>(false);
  const [sidebarWidth, setSidebarWidth] = useLocalStorage('sidebarWidth', 450);

  const apiKey = routeSearch?.apiKey ?? '';
  const sessionKey = routeSearch?.key ?? '';

  const fileNameParts = sessionKey.split('/');
  const fileName = fileNameParts[fileNameParts.length - 1];

  let host = 'import.io';
  if (sessionKey.includes('staging') || location.host.includes('staging')) {
    host = 'staging-owl.com';
  } else if (sessionKey.includes('demo') || location.host.includes('demo')) {
    host = 'demo-owl.com';
  }

  const debuggerFile = `https://api.${getTopLevelDomain()}/eventlog_new/download/${fileName}?${apiKey ? `_apikey=${apiKey}` : ''}`;

  // Queries
  const query = useQuery<EventRecord[]>({
    queryKey: ['player', debuggerFile, fileName],
    refetchOnWindowFocus: false,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
    staleTime: Infinity,
    queryFn: async (): Promise<null | any> => {
      if (!fileName) {
        return null;
      }
      const data = await fetch(debuggerFile, {
        credentials: 'include',
      }).then((res) => res.json());

      if (data.code) {
        // if (data.code === 2004) {
        //   window.location.href = `https://enter.${getTopLevelDomain()}/?redirect=${encodeURIComponent(window.location.href)}`
        // }
        throw new Error(data.message);
      }

      console.log('Data loaded');

      setDebuggerBlob(
        URL.createObjectURL(
          new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' }),
        ),
      );

      const isNewDebugger =
        data.find((e: any) => e.type === 'tree-mirror-version')?.event === 'new';

      if (!isNewDebugger) {
        // Redirect to old debugger
        window.location.replace(
          `https://debugger-old.${host}/player?${window.location.search.slice(1)}`,
        );
      }

      return data;
    },
  });

  useEffect(() => {
    if (query.data && !window.player) {
      const events = query
        .data!.filter((event: any) => event.type === 'tree-mirror')
        .map((event: any) => event.event);

      const lastEventTime = events[events.length - 1]?.timestamp;

      if (events.length >= 2) {
        window.player = new rrwebPlayer({
          target: document.getElementById('player-container')!,
          props: {
            events: events,
            autoPlay: true,
          },
        });

        window.player.getReplayer().on('event-cast', (event) => {
          if ((event as { timestamp: number }).timestamp === lastEventTime) {
            console.log('Last event played');
            setPlayedEvents(query.data);
            return;
          }
          setPlayedEvents(
            query.data.filter((e) => {
              return e.timestamp < (event as { timestamp: number }).timestamp;
            }),
          );
        });
      }
    }

    // Perform any setup or initialization here
    // For example, fetching data, subscribing to events, etc.

    return () => {
      // This code runs when the component is unmounted
      console.log('Component is unmounted');
    };
  }, [sessionKey, !!query.data]);

  const finalMessage = useMemo(() => {
    const allMessages = (query.data ?? []).filter(
      (event: any) => event.type === 'task-processing-message-sent',
    );
    return allMessages[allMessages.length - 1]?.event;
  }, [sessionKey, !!query.data]);

  const metricsMessage = useMemo(() => {
    return (query.data ?? []).find((event) => event.type === 'firehose-record')?.event;
  }, [sessionKey, !!query.data]);

  const firstConsoleEvent = useMemo(() => {
    return (query.data ?? []).find((e) => e.type === 'website-console-updated');
  }, [sessionKey, !!query.data]);

  const tracer = useMemo(() => {
    return query.data?.find((e) => e.type === 'tracer')?.event?.traceEvents;
  }, [sessionKey, !!query.data]);

  const consoleText = playedEvents
    .filter((e) => e.type === 'website-console-updated' && e?.event?.args?.length > 0)
    .map((e) => {
      const [logType, ...logArgs] = e.event.args ?? [];
      return `[${Math.round(e.timestamp - (firstConsoleEvent?.timestamp ?? 0))
        .toString()
        .padStart(
          6,
          ' ',
        )}ms] [${(logType?.toString() ?? '???').padStart(5, ' ')}] ${logArgs.join(' ')}`;
    })
    .join('\n');

  const dataItems = playedEvents
    .filter((e) => e.type === 'action-ended' && e?.event?.name === 'ExtractDataAction')
    .map((e) => {
      return e.event.result;
    });

  const lastDataItem = dataItems[dataItems.length - 1] ?? '';

  const playedActions = playedEvents.filter(
    (e) => e.type === 'action-started' || e.type === 'action-ended' || e.type === 'page-stage',
  );

  const gotoOptions = playedEvents.filter((e) => e.type === 'goto-options')?.[0]?.event || {};

  const debuggerEvents = playedEvents.filter((e) => e.type === 'debugger') as DebuggerEvent[];

  const tabs = [
    [
      { title: 'None', key: 'none' },
      { title: 'Summary', key: 'summary' },
      { title: 'Final message', key: 'final' },
      { title: 'Metrics', key: 'metrics' },
      { title: 'Inputs', key: 'inputs' },
      { title: 'Data', key: 'data' },
    ],
    [
      { title: 'Options', key: 'goto' },
      { title: 'Console', key: 'console' },
      { title: 'Network', key: 'network' },
      { title: 'Actions', key: 'actions' },
      { title: 'Tracer', key: 'tracer' },
    ],
  ];

  // Make iframe scrollable when checkbox is checked
  useEffect(() => {
    const iframe: HTMLIFrameElement | null = document.querySelector(
      '.replayer-wrapper iframe',
    ) as HTMLIFrameElement;

    if (iframe) {
      iframe.setAttribute('scrolling', isScrollable ? 'yes' : 'no');
      // enable pointer events on iframe
      iframe.style.pointerEvents = isScrollable ? 'auto' : 'none';
    }
  }, [isScrollable]);

  return (
    <div>
      <div className='h-screen flex flex-col'>
        <nav
          className='flex flex-row justify-between items-center content-stretch'
          style={{ borderBottom: '1px solid #CCC' }}
        >
          <div className='p-2 pl-10 flex flex-row items-center'>
            <div>
              <img
                src={logo}
                alt='Import.io'
                width={150}
              />
            </div>
            <div className='ml-10 inline-flex items-center'>
              <Button
                startContent={<IconArrowLeft size={20} />}
                as={Link}
                to='/'
                search={{
                  //@ts-expect-error I don't know why it fails
                  crawlRunId: routeSearch.crawlrunGuid!,
                  ...(routeSearch.apiKey ? { _apikey: routeSearch.apiKey } : {}),
                }}
              >
                Back
              </Button>
            </div>
            {finalMessage?.runId && (
              <div className='pl-10 p-2 text-xs leading-5'>
                <strong>Crawlrun:</strong> {finalMessage.runId}&nbsp;
                {debuggerBlob && (
                  <a
                    href={debuggerBlob}
                    download={routeSearch.crawlrunGuid + '.json'}
                  >
                    (JSON)
                  </a>
                )}
                <br />
                <strong>Task:</strong> {finalMessage.taskId}
                <br />
                <strong>Extractor:</strong>{' '}
                <a
                  className='text-blue-950 inline-flex items-center'
                  target='_blank'
                  href={`https://app.${host}/${metricsMessage.extractor_id}/`}
                  rel='noreferrer'
                >
                  {metricsMessage.extractor_id}{' '}
                  <IconExternalLink
                    size={13}
                    className='ml-1'
                  />
                </a>
                <br />
                <strong>Scrollable:</strong>{' '}
                <Checkbox
                  checked={isScrollable}
                  onChange={(e) => setScrollable(e.target.checked)}
                ></Checkbox>
              </div>
            )}
          </div>
          <div className='flex justify-center items-center p-4'>
            <div className='text-sm text-gray-500 mr-4 flex flex-col space-y-1 text-center'>
              {tabs.map((tabList, tabIndex) => {
                return (
                  <div
                    className='space-x-1'
                    key={'tabs-' + tabIndex}
                  >
                    {tabList.map((t) => {
                      return (
                        <Button
                          key={t.key}
                          size='sm'
                          radius='sm'
                          color={tab === t.key ? 'primary' : 'default'}
                          onClick={() => setTab(t.key)}
                        >
                          {t.title}
                        </Button>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </nav>
        <div className='flex flex-row flex-1 overflow-hidden'>
          <div
            className='flex-1 flex items-center justify-center overflow-auto'
            style={{ backgroundColor: '#b5bcbf' }}
          >
            {query.isLoading ? <Spinner /> : null}
            {query.isError ? <div>{query.error.message}</div> : null}
            {query.data ? <div id='player-container'></div> : null}
          </div>
          <Resizable
            defaultSize={{
              width: sidebarWidth,
            }}
            minWidth={150}
            maxWidth={1000}
            onResizeStop={(_e, _direction, _ref, d) => {
              setSidebarWidth(sidebarWidth + d.width);
            }}
            className='bg-gray-100'
            style={{
              display: tab === 'none' ? 'none' : 'block',
            }}
          >
            {query.data && (
              <div className='h-full overflow-y-auto'>
                {tab === 'summary' && (
                  <div className='overflow-y-auto p-4'>
                    <Table>
                      <TableHeader>
                        <TableColumn>Key</TableColumn>
                        <TableColumn>Value</TableColumn>
                      </TableHeader>
                      <TableBody>
                        <TableRow>
                          <TableCell>Status</TableCell>
                          <TableCell>{formatState(finalMessage?.type)}</TableCell>
                        </TableRow>
                        {finalMessage?.message && (
                          <TableRow>
                            <TableCell>Error</TableCell>
                            <TableCell>
                              <strong>{finalMessage?.message}</strong>
                            </TableCell>
                          </TableRow>
                        )}
                        <TableRow>
                          <TableCell>Final url</TableCell>
                          <TableCell>{finalMessage?.payload?.metrics?.finalUrl}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Status code</TableCell>
                          <TableCell>{metricsMessage?.status_code}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Rows extracted</TableCell>
                          <TableCell>{finalMessage?.payload?.metrics?.rowsExtracted}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Cell</TableCell>
                          <TableCell>{metricsMessage?.sub_cluster_name}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Engine</TableCell>
                          <TableCell>{metricsMessage?.browser_engine}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Engine version</TableCell>
                          <TableCell>{metricsMessage?.version}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Retry</TableCell>
                          <TableCell>
                            {metricsMessage?.retry_num} / {metricsMessage?.retry_count}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Proxy</TableCell>
                          <TableCell>
                            {metricsMessage?.proxy_type} ({metricsMessage?.proxy_country})
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                )}
                {tab === 'final' && (
                  <div className='overflow-y-auto p-4'>
                    <JsonView
                      value={finalMessage || null}
                      displayDataTypes={false}
                    />
                  </div>
                )}
                {tab === 'console' && (
                  <AceEditor
                    // mode="java"
                    theme='textmate'
                    readOnly={true}
                    width='100%'
                    height='100%'
                    value={consoleText}
                  />
                )}
                {tab === 'inputs' && (
                  <div className='overflow-y-auto p-4'>
                    <JsonView
                      value={query.data.find((e) => e.type === 'inputs')?.event ?? ''}
                      displayDataTypes={false}
                    />
                  </div>
                )}
                {tab === 'data' && (
                  <AceEditor
                    mode='json'
                    theme='textmate'
                    readOnly={true}
                    width='100%'
                    height='100%'
                    value={JSON.stringify(lastDataItem || '', null, 2)}
                  />
                )}
                {tab === 'network' && <NetworkTable debuggerEvents={debuggerEvents} />}
                {tab === 'actions' && (
                  <div className='overflow-y-auto p-4'>
                    <JsonView
                      value={playedActions || []}
                      displayDataTypes={false}
                    />
                  </div>
                )}
                {tab === 'goto' && (
                  <div className='overflow-y-auto p-4'>
                    <JsonView
                      value={gotoOptions || []}
                      displayDataTypes={false}
                    />
                  </div>
                )}
                {tab === 'metrics' && (
                  <div className='overflow-y-auto p-4'>
                    <JsonView
                      value={metricsMessage || null}
                      displayDataTypes={false}
                    />
                  </div>
                )}
                {tab === 'tracer' && (
                  <div className='overflow-y-auto p-4'>
                    <JsonView
                      value={tracer || null}
                      displayDataTypes={false}
                    />
                  </div>
                )}
              </div>
            )}
          </Resizable>
        </div>
      </div>
    </div>
  );
}
